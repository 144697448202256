
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import Footer from '@/components/footer/index.vue'

export default {
    name: 'information',
    components: {
        homeTitlepc,
        Footer,
        
    },

    mounted () {
     
    },

    created(){
        this.getCategory()

    },
    methods: {
        changeZ(v,e) {
            this.zNum=e
            this.cNum=0
            this.category_children=v.children
            this.category_id=v.children[0].category_id
            this.changeC(v.children[0],0)
        },
        changeC(v,e) {
            this.cNum=e
            this.category_id=v.category_id
            this.getList()
        },
        getCategory(){
            this.$api.news.category_list({
                location: 2,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.category=res.list
                if(res.list[0].children.length !=0){
                    this.category_children=res.list[0].children
                    this.category_id=res.list[0].children[0].category_id

                }
                this.getList()

            }).catch(err => {
                console.log(err)
            })

        },
        getList(){
            this.$api.news.article_list({
                page:this.page,
                title:this.title,
                category_id: this.category_id,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.daList=res.list
                this.num =res.obj

            }).catch(err => {
                console.log(err)
            })

        },
        changeTablePage(page) {
            this.page=page
            this.getList()
        },









        check(e){
            this.$router.push({
                path:'/wap/details',
                query:{article_id:e.article_id,see:1}
            })

        },

    },

    data () {
        return {
            config,
            active:0,
            page:1,
            daList:[],
            labList:[],
            num:{},
            zNum:0,
            cNum:0,
            category_id:'',//分类id
            title:'',//文章标题
            category:[],
            category_children:[],
            
           
            
        }
    }
}
